import subway from "../../assets/img/properties/u_subway.svg";
import highway from "../../assets/img/properties/u_flip-h-alt.svg";
import bag from "../../assets/img/properties/shopping-bag-02.svg";
import pin from "../../assets/img/properties/u_map-pin-alt.svg";
import suitcase from "../../assets/img/properties/u_suitcase.svg";
import building from "../../assets/img/properties/building-07.svg";
import train from "../../assets/img/properties/train.svg";
import done from "../../assets/img/properties/done.svg";
import shoppingCart from "../../assets/img/properties/shopping-cart-03.svg";
import plane from "../../assets/img/properties/u_plane.svg";

import {useEffect, useState} from "react";
import { prettyCurrency } from '../Utilities';

const icons = {
    "suitCase": suitcase,
    "train": train,
    "subway": subway,
    "shoppingCart": shoppingCart,
    "plane": plane,
    "highway": highway,
    "pin": pin,
    "building": building,
    "bag": bag
}


function ProjectDescriptionTabularBody(props) {

    const [propertyData, setPropertyData] = useState(null);
    useEffect(() => {
        async function loadPropertyData(id) {
            try {
                const response = await fetch(`${process.env.REACT_APP_S3_PUBLIC_BUCKET_URL}/${props.data.id}/project-details.json`);
                const propertyDataObject = await response.json()
                setPropertyData(propertyDataObject);
            }catch(error) {
                console.log('Error getting project details', error)
                setPropertyData(null);
            }
        }

        if (props.data && props.data.id) {
            loadPropertyData(props.data.id);
        }
    });

    const projectDetails = props.data;

    if(!projectDetails){
      return <></>;
    }

    return (
        <div className="property-details-main-column">
            {showNav(propertyData)}

            {showGeneral(projectDetails)}

            {showActivity(projectDetails)}

            {showHighlights(propertyData)}

            {showLocation(propertyData)}

            {showMarket(propertyData)}

            {showSizeAndDimension(propertyData)}

            {showDocuments(propertyData)}
        </div>
    );
}

function showNav(propertyData) {

    return (<div className="pdmc-nav d-lg-block d-none">
        <ul className="nav">
            <li className="active">
                <a className=" pdmd-na-1" href="#GENERAL">
                    GENERAL
                </a>
            </li>

            <li className="active">
                <a className=" pdmd-na-1" href="#activity">
                    Activities
                </a>
            </li>

            {
                propertyData && propertyData.geography && propertyData.geography.highlights ? (
                    <li>
                        <a className="pdmd-na-2" href="#Salient-features">
                            Salient features
                        </a>
                    </li>
              ): <></>
            }
            {
                propertyData && propertyData.geography && propertyData.geography.location ? (
                  <li>
                      <a className="pdmd-na-3" href="#location">
                          location
                      </a>
                  </li>
                ) : <></>
            }
            {
                propertyData && propertyData.geography && propertyData.geography.market ? (
                  <li>
                      <a className="pdmd-na-4" href="#market">
                          market
                      </a>
                  </li>
                ) : <></>
            }
            {
                propertyData && propertyData.geography && propertyData.geography.geometry ? (
                    <li>
                        <a className="pdmd-na-5" href="#size-dimension">
                            size & dimension
                        </a>
                    </li>
                ) : <></>
            }
            {
                propertyData && propertyData.documentsFolderLink ? (
                <li>
                    <a className="pdmd-na-6" href="#documents">
                        Documents
                    </a>
                </li>
                ) : <></>}
        </ul>
    </div>);
}

function showGeneral(propertyDetails) {
    return (<div className="pdmc-box pdmc-box-GENERAL" id="GENERAL">
        <div
            className="title d-flex justify-content-between"
            data-bs-toggle="collapse"
            data-bs-target="#pdmc-b1"
        >
            <h4>General description</h4>
            <div className="ind-icon">
                <i className="fas fa-angle-down text-danger d-lg-none"></i>
            </div>
        </div>
        <div className="pdmc-box-m collapse" id="pdmc-b1">
            <div className="pdmc-m-inner">
                <div className="pdmc-d1-row pb-0">
                    <div className="single">
                        <h4>{propertyDetails?.roi}%</h4>
                        <p>Target ROI</p>
                    </div>
                    <div className="single">
                        <h4>{propertyDetails?.durationMonths} Months</h4>
                        <p>Investment Duration</p>
                    </div>
                    <div className="single">
                        <h4>{prettyCurrency(propertyDetails?.budget)}</h4>
                        <p>Total Budget</p>
                    </div>
                    <div className="single">
                        <h4>{prettyCurrency(propertyDetails?.sharePrice)}</h4>
                        <p>share Price</p>
                    </div>
                    <div className="single">
                        <h4>{propertyDetails?.numberOfShares}</h4>
                        <p>Total shares</p>
                    </div>
                    <div className="single">
                        <h4>{propertyDetails?.minInvestment}</h4>
                        <p>Min Investment</p>
                    </div>
                </div>

                <div className="fill-xt-outer">
                    <h4>
                        <span className="mbl-clr">{prettyCurrency(propertyDetails?.funded)}</span>
                        &nbsp;funded&nbsp;
                        <span className="mbl-clr">of {prettyCurrency(propertyDetails?.budget)}</span>
                    </h4>
                    <div className="fill-xt">
                        <div
                            className="filled"
                            style={{
                                width: "85%",
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

function showLocation(propertyData) {

    const geography = propertyData && propertyData.geography ? propertyData.geography : null;

    if (!geography ){
        return <></>
    }

    return (<div className="pdmc-box pdmc-box-location" id="location">
        <div
            className="title d-flex justify-content-between"
            data-bs-toggle="collapse"
            data-bs-target="#pdmc-b3"
        >
            <h4>location</h4>
            <div className="ind-icon">
                {" "}
                <i className="fas fa-angle-down text-danger d-lg-none"></i>
            </div>
        </div>
        <div className="pdmc-box-m pdmc-location-b-outer collapse" id="pdmc-b3">
            <div className="d-flex flex-wrap">
                <div className="pdmc-m-inner">
                    <div className="pdmc-lct-wrap">
                        {
                            geography.location.map((el) => {
                                return (<div className="pdmc-lct-single" key={el.title}>
                                    <div className="top">
                                        <div className="icon">
                                            <img src={icons[el.icon]} alt=""/>
                                        </div>
                                        <h5>{el.title}:</h5>
                                        <h4>{el.value}</h4>
                                    </div>
                                    <p>{el.description}</p>
                                </div>)
                            })
                        }
                    </div>
                </div>
                <div className="map">
                    <iframe width="603" height="601" id="gmap_canvas"
                            src={geography.googleMapUrl}
                            frameBorder="0"
                            marginHeight="0"
                            marginWidth="0">
                    </iframe>
                </div>
            </div>
        </div>
    </div>);
}

function showMarket(propertyData) {

    const market = propertyData && propertyData.geography && propertyData.geography.market ? propertyData.geography.market : null;
    if (!market ){
        return <></>
    }
    return (<div className="pdmc-box pdmc-box-market" id="market">
        <div
            className="title d-flex justify-content-between"
            data-bs-toggle="collapse"
            data-bs-target="#pdmc-b4"
        >
            <h4>market</h4>
            <div className="ind-icon">
                {" "}
                <i className="fas fa-angle-down text-danger d-lg-none"></i>
            </div>
        </div>
        <div className="pdmc-box-m collapse" id="pdmc-b4">
            <div className="pdmc-m-inner">
                <div className="pdmc-mkt-wrap">
                    {
                        market.map((el) => {
                            return (
                                <div className="pdmc-mkt-single" key={el.title}>
                                    <div className="icon">
                                        <img src={icons[el.icon]} alt=""/>
                                    </div>
                                    <div className="cont-s">
                                        <h4>{el.value}</h4>
                                        <p>{el.title}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>);
}


function showDocuments(propertyData) {
    if(!propertyData || !propertyData.documentsFolderLink){
        return <></>
    }
    return (<div className="pdmc-box pdmc-box-documents" id="documents">
        <div
            className="title d-flex justify-content-between"
            data-bs-toggle="collapse"
            data-bs-target="#pdmc-b6"
        >
            <h4>Documents</h4>
            <div className="ind-icon">
                {" "}
                <i className="fas fa-angle-down text-danger d-lg-none"></i>
            </div>
        </div>
        <div className="pdmc-box-m collapse" id="pdmc-b6">
            <div className="pdmc-m-inner">
                <iframe
                    src={propertyData.documentsFolderLink}
                    style={{width: '100%', height: 'auto !important', border: '0'}}></iframe>
            </div>
        </div>
    </div>);
}

function showActivity(propertyDetails) {
    return (<div className="pdmc-box pdmc-box-activity" id="activity">
        <div
          className="title d-flex justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#pdmc-b2"
        >
            <h4>Recent activities</h4>
            <div className="ind-icon">
                {" "}
                <i className="fas fa-angle-down text-danger d-lg-none"></i>
            </div>
        </div>
        <div className="pdmc-box-m collapse" id="pdmc-b2">
            <div className="pdmc-m-inner">

                <div className="pdmc-sf-wrap">
                    {
                      propertyDetails && propertyDetails.activities && propertyDetails.activities.length >0 ? (
                          propertyDetails.activities.map((el) => {
                              return (
                                <div className="pdmc-sf-single" key={el.id}>
                                    <h4 className="h4" style={{marginBottom: 0}}>
                                        <div className="icon">
                                            <img src={done} alt=""/>
                                        </div>
                                        <span className="txt">{el.title}</span>
                                    </h4>
                                    <span className="timestamp">{new Date(Date.parse(el.createdAt)).toDateString()}</span>
                                        <p>
                                        {el.description}
                                    </p>
                                </div>
                              )
                          })
                      ): (<>No activities yet</>)
                    }
                </div>
            </div>
        </div>
    </div>);
}

function showHighlights(propertyData) {
    const highlights = propertyData && propertyData.geography && propertyData.geography.highlights ? propertyData.geography.highlights : null;

    if (!highlights ){
        return <></>
    }
    return (<div className="pdmc-box pdmc-box-Salient-features" id="Salient-features">
        <div
            className="title d-flex justify-content-between"
            data-bs-toggle="collapse"
            data-bs-target="#pdmc-b2"
        >
            <h4>Salient features</h4>
            <div className="ind-icon">
                {" "}
                <i className="fas fa-angle-down text-danger d-lg-none"></i>
            </div>
        </div>
        <div className="pdmc-box-m collapse" id="pdmc-b2">
            <div className="pdmc-m-inner">
                <div className="pdmc-sf-wrap">
                    {
                        highlights.map((el) => {
                            return (
                                <div className="pdmc-sf-single" key={el.title}>
                                    <h4 className="h4">
                                        <div className="icon">
                                            <img src={icons[el.icon]} alt=""/>
                                        </div>
                                        <span className="txt">{el.title}</span>
                                    </h4>
                                    <p>
                                        {el.description}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>);
}

function showSizeAndDimension(propertyData) {

    const geometry = propertyData && propertyData.geography && propertyData.geography.geometry ? propertyData.geography.geometry : null;
    if (!geometry ){
        return <></>
    }

    return (<div className="pdmc-box pdmc-box-size-&-dimension" id="size-dimension">
        <div
            className="title d-flex justify-content-between"
            data-bs-toggle="collapse"
            data-bs-target="#pdmc-b5"
        >
            <h4>size & dimension</h4>
            <div className="ind-icon d-lg-none">
                {" "}
                <i className="fas fa-angle-down text-danger"></i>
            </div>
        </div>
        <div className="pdmc-box-m collapse" id="pdmc-b5">
            <div className="pdmc-m-inner">
                <div className="pdmc-d1-row">
                    <div className="single">
                        <h6>Total Area</h6>
                        <h4 className="mb-0">{geometry.totalArea}</h4>
                    </div>
                    <div className="single">
                        <h6>Number of sides</h6>
                        <h4 className="mb-0">{geometry.sides}</h4>
                    </div>
                    <div className="single">
                        <h6>Facing</h6>
                        <h4 className="mb-0">{geometry.face}</h4>
                    </div>
                    <div className="single">
                        <h6>East-West</h6>
                        <h4 className="mb-0">{geometry.eastWest}</h4>
                    </div>
                    <div className="single">
                        <h6>North-South</h6>
                        <h4 className="mb-0">{geometry.northSouth}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default ProjectDescriptionTabularBody;
