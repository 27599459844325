import property1 from '../../assets/img/properties/property-details-img-gallery-img1.png';
import property2 from '../../assets/img/properties/property-details-img-gallery-img2.png';
import property3 from '../../assets/img/properties/property-details-img-gallery-img3.png';
import PlayBtn from '../../assets/img/properties/play-button.svg';

function ImgGallery() {
	return (
		<div className='property-details-img-gallery d-grid'>
			<div className='p-gallery-item p-gallery-item-huge'>
				<img className='full-img' src={property1} alt='' />
			</div>
			<div className='p-gallery-item'>
				<img className='full-img' src={property2} alt='' />
					<img src={PlayBtn} alt='' />
			</div>
			<div className='p-gallery-item'>
				<img className='full-img' src={property3} alt='' />
				<a href='#' className='overlay-cont'>
					<span className='mb-0'>+17</span>
				</a>
			</div>
		</div>
	);
}

export default ImgGallery;
