import {Link} from "react-router-dom";
import {prettyCurrency} from '../Utilities';

const img = require('../../assets/img/my-investment.png')

function InvestmentCard(props) {

    if (!props.investment || props.investment.length === 0) {
        return <></>;
    }
    const investment = props.investment[0];
    const project = props.projects ? props.projects.find(x => x.id === investment.projectId) : null;
    if (!project) {
        return <></>;
    }

    return (
        <div className='s-card'>
            <div className='s-card-header'>
                <h4 className='mb-0'>My Investments</h4>
                {/*<Link to={'/project-details/' + project?.id} className='a-btn'>*/}
                {/*    <span className='txt'>Show project</span>*/}
                {/*    <i className='far fa-arrow-right'></i>*/}
                {/*</Link>*/}
            </div>
            <div className='s-card-in-content'>
                <div className='s-card-row1'>
                    <div className='image'>
                        <img src={img} alt=''/>
                    </div>
                    <div className='text-side'>
                        <h3 className='h3'>{project.title}</h3>
                        <p className='location-text'>
                            <img
                                src={require('../../assets/icon/location-theme2.svg')}
                                alt=''
                                className='im'
                            />
                            <span className='txt'>{project.location}</span>
                        </p>
                    </div>
                </div>
                <div className='s-card-fill-row'>
                    <h4>
						<span className='color-theme1'>
							{prettyCurrency(project.funded)}
						</span>{' '}
                        funded of{' '}
                        <span className='color-theme1'>{prettyCurrency(project.budget)}</span>
                    </h4>
                    <div className='s-fill-bar'>
						<span
                            className='filled'
                            style={{width: '75%'}}
                        ></span>
                    </div>
                </div>
                <div className='bt-button-row mt-0'>
                    <Link to={'/project-details/' + project?.id} className='default-button min-w'>
                        Open Project details
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default InvestmentCard;
