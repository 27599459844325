import growthUp from "../../assets/icon/arrow-growth.svg";
import growthDown from "../../assets/icon/chart-down.svg";
import icon1 from "../../assets/icon/calendar.svg";
import icon2 from "../../assets/icon/switch-vertical-01.svg";
import icon3 from "../../assets/icon/bookmark-white.svg";
import icon4 from "../../assets/icon/trash-01.svg";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { prettyCurrency } from '../Utilities';
import moment from "moment";

function TransactionTable(props) {

  const refOne = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const handleOnClick = (event) => {
    let calendarDropdown = document.getElementById("calendar-dropdown");
    if (showPicker && !calendarDropdown.contains(event.target)) {
      setShowPicker(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleOnClick);
    return () => {
      window.removeEventListener("click", handleOnClick);
    };
  });
  return (
    <>
      {/* <div className='table-box-title'>
				<div className='tbl-i-menu'>
					<ul>
						<li>
							<a
								ref={refOne}
								className='range-picker position-relative'
								onClick={() => setShowPicker(!showPicker)}
								id='calendar-dropdown'
							>
								<img src={icon1} alt='' />
								{showPicker ? (
									<DateRangePicker
										className='rangepicker shadow-sm'
										rangeColors={['rgb(232, 62, 140)']}
										onChange={item =>
											setRange([item.selection])
										}
										showSelectionPreview={true}
										moveRangeOnFirstSelection={false}
										months={2}
										ranges={range}
										direction={
											isSmallScreen
												? 'vertical'
												: 'horizontal'
										}
										autoFocus
									/>
								) : (
									''
								)}
							</a>
						</li>
						<li>
							<a href='#'>
								<img src={icon2} alt='' />
							</a>
						</li>
						<li>
							<a href='#'>
								<img src={icon3} alt='' />
							</a>
						</li>
						<li>
							<a href='#'>
								<img src={icon4} alt='' />
							</a>
						</li>
					</ul>
				</div>

				<div className='t-search'>
					<input
						type='text'
						className='input d-md-block d-none'
						placeholder='Search...'
					/>
					<button
						type='text'
						className='input input-button d-md-none'
						placeholder='Search...'
					>
						<img
							src={require('../../assets/icon/search-refraction.svg')}
							alt=''
						/>
					</button>
				</div>
			</div> */}
      <div className="table-model table-model-bordered table-mdl-str3 d-md-block d-none">
        <table>
          <thead>
          <tr>
            <td>DATE</td>
            <td>AMOUNT</td>
            <td>
              <div className="dropdown th-dropdown">
                <a href="#" data-bs-toggle="dropdown">
                  <span className="txt">TYPE</span>
                  <i className="fas fa-chevron-down chev-down"></i>
                </a>
                <div className="dropdown-menu dropdown-sss dropdown-menu-end">
                  <div className="drop-select">
                    <ul>
                      <li>
                        <a href="#">
                          <div className="p-icon">
                            <img src={growthUp} alt="" />
                          </div>
                          <span className="txt">DR</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="p-icon">
                            <img src={growthDown} alt="" />
                          </div>
                          <span className="txt">CR</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="dropdown th-dropdown">
                <a href="#" data-bs-toggle="dropdown">
                  <span className="txt">MODE</span>
                  <i className="fas fa-chevron-down chev-down"></i>
                </a>
                <div className="dropdown-menu dropdown-sss dropdown-menu-end">
                  <div className="drop-select">
                    <ul>
                      <li>
                        <a href="#">RTGS</a>
                      </li>
                      <li>
                        <a href="#">CHQ</a>
                      </li>
                      <li>
                        <a href="#">DD</a>
                      </li>
                      <li>
                        <a href="#">Cash</a>
                      </li>
                      <li>
                        <a href="#">RTG</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
            <td>PROJECT</td>
            <td>DETAILS</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
            {props.data.length === 0 ? (
              <tr>
                <td className="text-center" colSpan="6">
                  You have not made any transactions yet.
                </td>
              </tr>
            ) : (
              props.data.map((list, id) => {
                return (
                    <tr key={id}>
                      <td>{moment(new Date(list.date)).format('DD-MM-YYYY')}</td>
                      <td>
                        <span className="color-theme1 fw-500">
                          {prettyCurrency(list.amount)}
                        </span>
                      </td>
                      <td>
                        <div className="in-arrow">
                          {list.type == "credit" ? (
                            <img src={growthUp} alt="" />
                          ) : (
                            <img src={growthDown} alt="" />
                          )}
                        </div>
                      </td>
                      <td>
                        <span className="td-cheap">{list.mode}</span>
                      </td>
                      <td>{list.project.title}</td>
                      <td>{list.details}</td>
                      <td>
                        <a href="#" className="td-bookmark">
                          <svg
                            width="15"
                            height="19"
                            viewBox="0 0 15 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.35046 5.78682C1.35046 4.32492 1.35046 3.59397 1.63497 3.0356C1.88523 2.54444 2.28455 2.14511 2.77571 1.89486C3.33408 1.61035 4.06503 1.61035 5.52693 1.61035H9.35537C10.8173 1.61035 11.5482 1.61035 12.1066 1.89486C12.5977 2.14511 12.9971 2.54444 13.2473 3.0356C13.5318 3.59397 13.5318 4.32492 13.5318 5.78682V17.2721L7.44115 13.7917L1.35046 17.2721V5.78682Z"
                              stroke="#142C4B"
                              strokeWidth="1.7402"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </a>
                      </td>
                    </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      <div className="mbl-table mbl-table-str1 d-md-none">
        <div className="tbl-hd">
          <div className="tbl-tr">
            <div className="column str-col">PROJECT</div>
            <div className="column end-col">AMOUNT</div>
          </div>
        </div>
        <div className="tbl-bd">
          {props.data.length === 0 ? (
            <div className="text-center py-3">No transactions to show</div>
          ) : (
            props.data.map((list, id) => {

              var prettyDate = moment(new Date(list.date)).format("DD-MM-YYYY");
              return (
                <div key={id} className="tbl-tr">
                  <div className="row1">
                    <div className="left str-col">
                      <h5>{list.project.title}</h5>
                      <div className="cont-d-b-i">
                        <span className="cont-d">{prettyDate}</span>
                        <span className="ellipse-one"></span>
                        <div className="cont-b">{list.mode}</div>
                        <div className="cont-i">
                          
                        {list.type == "credit" ? (
                            <img src={growthUp} alt="" />
                          ) : (
                            <img src={growthDown} alt="" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="right end-col">
                      <h4 className="color-theme1 fw-500">{prettyCurrency(list.amount)}</h4>
                      {/*<a*/}
                      {/*  href="#"*/}
                      {/*  className="td-bookmark ms-sm-3 ms-2"*/}
                      {/*>*/}
                      {/*  <svg*/}
                      {/*    width="15"*/}
                      {/*    height="19"*/}
                      {/*    viewBox="0 0 15 19"*/}
                      {/*    fill="none"*/}
                      {/*    xmlns="http://www.w3.org/2000/svg"*/}
                      {/*  >*/}
                      {/*    <path*/}
                      {/*      d="M1.35046 5.78682C1.35046 4.32492 1.35046 3.59397 1.63497 3.0356C1.88523 2.54444 2.28455 2.14511 2.77571 1.89486C3.33408 1.61035 4.06503 1.61035 5.52693 1.61035H9.35537C10.8173 1.61035 11.5482 1.61035 12.1066 1.89486C12.5977 2.14511 12.9971 2.54444 13.2473 3.0356C13.5318 3.59397 13.5318 4.32492 13.5318 5.78682V17.2721L7.44115 13.7917L1.35046 17.2721V5.78682Z"*/}
                      {/*      stroke="#142C4B"*/}
                      {/*      strokeWidth="1.7402"*/}
                      {/*      strokeLinecap="round"*/}
                      {/*      strokeLinejoin="round"*/}
                      {/*    ></path>*/}
                      {/*  </svg>*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

export default TransactionTable;
