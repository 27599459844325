import React from 'react';
import {Link} from 'react-router-dom';
import mapMarker from '../../assets/icon/map-marker.svg';
import findInfo from '../../assets/img/find-info.png';
import elevation from '../../assets/img/properties/shop-flat-elevation-sample-1.jpg';
import { prettyCurrency } from '../Utilities';

function ProjectsList({properties}) {
    return (
        <>
            {properties?.map((el, id) => {
                return (
                    projectElement({el, id})
                );
            })}
            </>
    );
}

function projectElement({el, id}) {
    /** <div className='sgl-properties mbl-first'> **/
    return (
        <React.Fragment key={id}>
            <div className='sgl-properties' style={{height: '500px'}}>
                <div className='find-right'>
                    <div
                        className='find-info'
                        style={{
                            backgroundImage: `url(${findInfo})`,
                        }}
                    >
                        <div className='find-info-head' key={id}>
                            <h4 className='text-white'>{el.title}</h4>
                            <p className='grey-text d-flex align-items-center'>
                                <img
                                    src={mapMarker}
                                    className='me-2'
                                    alt=''
                                />
                                {el?.location}
                            </p>
                        </div>

                        <div className='find-info-content row gx-0'>
                            {/* <!-- find-info-innder-item --> */}
                            <div className='find-info-item col-3'>
                                <p className='large-text fw-semibold text-white'>
                                    {el.roi}%
                                </p>
                                <span className='grey-text small-text'>
                        Target ROI
                        </span>
                            </div>
                            {/* <!-- find-info-innder-item-end --> */}

                            {/* <!-- find-info-innder-item --> */}
                            <div className='find-info-item col-5'>
                                <p className='large-text fw-semibold text-white'>
                                    {el.durationMonths} Months
                                </p>
                                <span className='grey-text small-text'>
                        Investment Duration
                        </span>
                            </div>
                            {/* <!-- find-info-innder-item-end --> */}

                            {/* <!-- find-info-innder-item --> */}
                            <div className='find-info-item col-4'>
                                <p className='large-text fw-semibold text-white'>
                                  {prettyCurrency(prettyCurrency(el.budget))}
                                </p>
                                <span className='grey-text small-text'>
                        Total Budget
                        </span>
                            </div>
                            {/* <!-- find-info-innder-item-end --> */}

                            {/* <!-- find-info-innder-item --> */}
                            <div className='find-info-item col-3'>
                                <p className='large-text fw-semibold text-white'>
                                    {el?.numberOfShares}
                                </p>
                                <span className='grey-text small-text'>
                        Number of Shares
                        </span>
                            </div>
                            {/* <!-- find-info-innder-item-end --> */}

                            {/* <!-- find-info-innder-item --> */}
                            <div className='find-info-item col-5'>
                                <p className='large-text fw-semibold text-white'>
                                    {prettyCurrency(el.sharePrice)}
                                </p>
                                <span className='grey-text small-text'>
                        Share Price
                        </span>
                            </div>
                            {/* <!-- find-info-innder-item-end --> */}

                            {/* <!-- find-info-innder-item --> */}
                            <div className='find-info-item col-4'>
                                <p className='large-text fw-semibold text-white'>
                                    {el.minInvestment}
                                </p>
                                <span className='grey-text small-text'>
                        Min Investment
                        </span>
                            </div>
                            {/* <!-- find-info-innder-item-end --> */}
                        </div>

                        <div className='find-progress-area'>
                            <p className='fw-semibold text-white large-text'>
                                {prettyCurrency(el.funded)} funded of {prettyCurrency(el.budget)}
                            </p>
                            <div className='find-progress'>
                                <div className='find-progress-bar'></div>
                            </div>
                        </div>

                        <div className='text-center'>
                            <Link
                                target='_blank'
                                className='op-btn op-btn-white'
                                to={'/project-details/' + el.id}>
                                View details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sgl-properties' style={{height: '500px'}}>
                <div className='fl-image'>
                    {/*<Link to='/property-details'>*/}
                    <img src={elevation} alt=''/>
                    {/*</Link>*/}
                </div>
            </div>
        </React.Fragment>);
}

export default ProjectsList;
