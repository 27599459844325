import MLogo from "../assets/img/mobile-logo.png";
import { Link, useHistory } from "react-router-dom";
import routes from "../routes";
import { useState } from "react";
import { logout } from "../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCurrentUser } from "../store/actions/userAction";
import {
  getMessageAct,
  updateMessageAct,
} from "../store/actions/messageAction";
import moment from "moment";
import { getPersonalDetailsAct } from "../store/actions/accountAction";
const NavBar = (props) => {
  const [messages, setMessages] = useState("");
  const [subject, setSubject] = useState("");
  const [date, setDate] = useState("");
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [notification, setNotification] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const { personalDetails } = useSelector((state) => state.account);

  const canvasOpen = () => {
    document.body.classList.add("offcanvas-opened");
  };
  const history = useHistory();
  const { message } = useSelector((state) => state.message);

  const getTitle = () => {
    let title = routes.find((route) => route.path == history.location.pathname);
    if (title) {
      return title.name;
    } else {
      return "N/A";
    }
  };

  const getFilteredNotification = () => {
    let not = message.rows.filter((noti) => {
      let temp = noti.messageType.split("-");
      if (temp?.length > 0 && temp[0] == "notification") {
        return noti;
      }
    });
    // console.log("Returning", not);

    if (not.length > 0) {
      return not.slice(0, 4);
    } else {
      return [];
    }
  };
  const naviagate = (messageType) => {
    if (messageType == "notification-projects") {
      history.push("/property-details");
    } else if (messageType == "notification-myinvestment") {
      history.push("/my-investments");
    } else if (messageType == "notification-transaction") {
      history.push("/transactions");
    } else if (messageType == "notification-message") {
      history.push("/messages");
    }
  };
  const menuIcon = (messageType) => {
    if (messageType == "notification-projects") {
      return <h4>OP.</h4>;
    } else if (messageType == "notification-myinvestment") {
      return (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 6.825C3.5 5.35486 3.5 4.61979 3.78611 4.05827C4.03778 3.56435 4.43935 3.16278 4.93327 2.91111C5.49479 2.625 6.22986 2.625 7.7 2.625H13.3C14.7701 2.625 15.5052 2.625 16.0667 2.91111C16.5607 3.16278 16.9622 3.56435 17.2139 4.05827C17.5 4.61979 17.5 5.35486 17.5 6.825V18.375L15.0938 16.625L12.9062 18.375L10.5 16.625L8.09375 18.375L5.90625 16.625L3.5 18.375V6.825Z"
            stroke="#A9A9A9"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    } else if (messageType == "notification-transaction") {
      return (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.31254 5.31266L7.08337 3.54183M7.08337 3.54183L5.31254 1.771M7.08337 3.54183H5.31254C3.35653 3.54183 1.77087 5.12749 1.77087 7.0835M15.9375 15.9377L14.1667 17.7085M14.1667 17.7085L15.9375 19.4793M14.1667 17.7085H15.9375C17.8935 17.7085 19.4792 16.1228 19.4792 14.1668M9.02157 5.75537C9.61132 3.46405 11.6913 1.771 14.1667 1.771C17.1007 1.771 19.4792 4.14948 19.4792 7.0835C19.4792 9.55889 17.7862 11.6389 15.4949 12.2286M12.3959 14.1668C12.3959 17.1008 10.0174 19.4793 7.08337 19.4793C4.14936 19.4793 1.77087 17.1008 1.77087 14.1668C1.77087 11.2328 4.14936 8.85433 7.08337 8.85433C10.0174 8.85433 12.3959 11.2328 12.3959 14.1668Z"
            stroke="#A9A9A9"
            strokeWidth="1.77083"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    } else if (messageType == "notification-message") {
      return (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.0365 15.9373L13.1548 10.6248M8.09528 10.6248L2.21361 15.9373M1.77087 6.19775L9.00023 11.2583C9.58564 11.6681 9.87835 11.873 10.1967 11.9523C10.478 12.0225 10.7721 12.0225 11.0533 11.9523C11.3717 11.873 11.6644 11.6681 12.2499 11.2583L19.4792 6.19775M6.02087 17.7082H15.2292C16.7168 17.7082 17.4607 17.7082 18.0289 17.4187C18.5287 17.164 18.935 16.7576 19.1897 16.2578C19.4792 15.6896 19.4792 14.9458 19.4792 13.4582V7.7915C19.4792 6.30386 19.4792 5.56004 19.1897 4.99184C18.935 4.49204 18.5287 4.08568 18.0289 3.83102C17.4607 3.5415 16.7168 3.5415 15.2292 3.5415H6.02087C4.53323 3.5415 3.78941 3.5415 3.22121 3.83102C2.72141 4.08568 2.31505 4.49204 2.06039 4.99184C1.77087 5.56004 1.77087 6.30386 1.77087 7.7915V13.4582C1.77087 14.9458 1.77087 15.6896 2.06039 16.2578C2.31505 16.7576 2.72141 17.164 3.22121 17.4187C3.78941 17.7082 4.53323 17.7082 6.02087 17.7082Z"
            stroke="#A9A9A9"
            strokeWidth="1.77083"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
  };
  const markAllAsReadMessages = () => {
    message?.rows?.length > 0 &&
      getFilteredNotification().length > 0 &&
      getFilteredNotification().forEach((element) => {
        dispatch(
          updateMessageAct(element.id, {
            data: {
              marked: true,
              subject: element.subject,
              content: element.content,
            },
          })
        );
        dispatch(getMessageAct());
      });
  };
  // console.log("menuIcon", menuIcon());

  useEffect(() => {
    dispatch(getPersonalDetailsAct(history));
    dispatch(getMessageAct());
    dispatch(getCurrentUser());
  }, []);
  return (
    <>
      {/* <!-- admin-header --> */}
      <div className="admin-main-header-outer">
        <div className="admin-main-header">
          <h3 className="mb-0 d-xl-block d-none">{getTitle()}</h3>
          <a href="index.html" className="mobile-logo d-xl-none">
            <img src={MLogo} alt="" />
          </a>

          <div className="header-right">
            <div className="header-user">
               <span className="txt">
                {personalDetails?.name ? personalDetails?.name : "Me"}
              </span>
              <div className="image">
                {personalDetails?.profilePicDownloadUrl ? (
                    <img
                        src={personalDetails?.profilePicDownloadUrl}
                        alt="profileImg"
                    />
                ) : (
                    <img alt="DP" src={require("../assets/img/avatar.png")} />
                )}
              </div>
            </div>
            <ul className="a-menu">
              {/*<li>*/}
              {/*  <a href="#">*/}
              {/*    <svg*/}
              {/*      width="23"*/}
              {/*      height="23"*/}
              {/*      viewBox="0 0 23 23"*/}
              {/*      fill="none"*/}
              {/*      xmlns="http://www.w3.org/2000/svg"*/}
              {/*    >*/}
              {/*      <path*/}
              {/*        d="M19.3973 19.3961L15.3904 15.3892M10.186 5.57928C12.7296 5.57928 14.7916 7.64128 14.7916 10.1849M17.555 10.1849C17.555 14.2547 14.2558 17.5539 10.186 17.5539C6.11628 17.5539 2.81708 14.2547 2.81708 10.1849C2.81708 6.11512 6.11628 2.81592 10.186 2.81592C14.2558 2.81592 17.555 6.11512 17.555 10.1849Z"*/}
              {/*        stroke="white"*/}
              {/*        strokeWidth="1.84224"*/}
              {/*        strokeLinecap="round"*/}
              {/*        strokeLinejoin="round"*/}
              {/*      />*/}
              {/*    </svg>*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li className="dropdown">
                <a href="#" data-bs-toggle="dropdown">
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_383_349)">
                      <path
                        d="M14.4167 16.6467C14.8117 18.1208 13.9368 19.6361 12.4627 20.0311C10.9885 20.4261 9.47328 19.5512 9.07828 18.0771M1.8995 10.7501C1.54073 9.46223 1.91382 8.06983 2.86847 7.13387M11.1931 5.34088C11.4733 4.83675 11.5656 4.22743 11.4046 3.62631C11.0754 2.39784 9.81269 1.66882 8.58423 1.99798C7.35576 2.32715 6.62673 3.58986 6.9559 4.81833C7.11697 5.41945 7.50157 5.90098 7.9963 6.19747M18.7212 6.2428C18.3879 4.94806 17.3686 3.92875 16.0739 3.59551M16.1159 8.75311C15.7871 7.52606 14.9093 6.49996 13.6757 5.90056C12.4421 5.30116 10.9536 5.17754 9.53778 5.55691C8.12195 5.93629 6.89472 6.78757 6.12606 7.92348C5.3574 9.0594 5.11029 10.3869 5.43908 11.614C5.98307 13.6442 5.87744 15.2638 5.5339 16.4946C5.14236 17.8973 4.9466 18.5987 4.99948 18.7395C5.05999 18.9006 5.10376 18.9449 5.26425 19.0071C5.40453 19.0614 5.99384 18.9035 7.17245 18.5877L18.1016 15.6592C19.2802 15.3434 19.8695 15.1855 19.9638 15.0683C20.0717 14.9342 20.0875 14.874 20.0594 14.7042C20.0348 14.5558 19.5145 14.0463 18.4741 13.0273C17.5612 12.1332 16.6599 10.7833 16.1159 8.75311Z"
                        stroke="white"
                        strokeWidth="1.84224"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_383_349">
                        <rect
                          width="22.1069"
                          height="22.1069"
                          fill="white"
                          transform="translate(0.00488281 0.0527344)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <div className="dropdown-overlay"></div>
                <div className="dropdown-menu notifications-dropdown dropdown-menu-end">
                  <div className="notifications-dropdown-inner">
                    <div className="dropdown-title">
                      <h4 className="mb-0">Notifications</h4>
                      <span
                        className="all-r-button"
                        onClick={() => {
                          markAllAsReadMessages();
                        }}
                      >
                        <span className="txt">Mark all as read</span>
                        <i
                          className="fas fa-check-circle"
                          style={{ paddingLeft: "8px" }}
                        ></i>
                      </span>
                    </div>

                    <div className="notifications-wrap">
                      {message?.rows?.length > 0 &&
                        getFilteredNotification().length > 0 &&
                        getFilteredNotification().map((not, idx) => {
                          return not.marked == false ? (
                            <span
                              key={idx}
                              // href={not.link}
                              className="notification-single"
                              onClick={() => {
                                dispatch(
                                  updateMessageAct(not.id, {
                                    data: {
                                      marked: true,
                                      subject: not.subject,
                                      content: not.content,
                                    },
                                  })
                                );
                                dispatch(getMessageAct());
                                naviagate(not.messageType);
                              }}
                            >
                              <div className="not-thumb">
                                {/* <h4>OP.</h4> */}
                                {menuIcon(not.messageType)}
                                {not.marked == false ? (
                                  <span className="dot"></span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="not-content">
                                <h4>{not.subject}</h4>
                                {/* <h6>Today at 9:42 AM</h6> */}
                                <h6>
                                  {moment(not.createdAt).format(
                                    "MMMM Do YYYY, h:mm a"
                                  )}
                                </h6>
                              </div>
                            </span>
                          ) : (
                            ""
                          );
                        })}
                      {/* <!-- notification-single --> */}

                      {/* <!-- notification-single-end --> */}
                      {/* <!-- notification-single --> */}
                      {/* <a
												href='#'
												className='notification-single'
											>
												<div className='not-thumb'>
													<h4>OP.</h4>
													<span className='dot'></span>
												</div>
												<div className='not-content'>
													<h4>
														The new RBI guidelines
														requires all the
														investors to...
													</h4>
													<h6>Today at 9:42 AM</h6>
												</div>
											</a>
										
											<a
												href='#'
												className='notification-single'
											>
												<div className='not-thumb'>
													<h4>OP.</h4>
												</div>
												<div className='not-content'>
													<h4>
														The new RBI guidelines
														requires all the
														investors to...
													</h4>
													<h6>Today at 9:42 AM</h6>
												</div>
											</a>
										
											<a
												href='#'
												className='notification-single'
											>
												<div className='not-thumb'>
													<h4>OP.</h4>
												</div>
												<div className='not-content'>
													<h4>
														The new RBI guidelines
														requires all the
														investors to...
													</h4>
													<h6>Today at 9:42 AM</h6>
												</div>
											</a> */}
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <a onClick={canvasOpen} className="offcanvas-open d-xl-none"></a>
          </div>
        </div>
      </div>
      {/* <!-- admin-header-end --> ); */}
      <div className="admin-sidebar admin-sidebar-mobile d-xl-none">
        <div className="admin-sidebar-inner">
          <div className="sidebar-menu">
            <ul>
              {/*<li>*/}
              {/*  <Link*/}
              {/*    className={`${*/}
              {/*      history.location.pathname == "/dashboard" ? "active" : ""*/}
              {/*    }`}*/}
              {/*    to="/dashboard"*/}
              {/*  >*/}
              {/*    <div className="icon">*/}
              {/*      <svg*/}
              {/*        width="22"*/}
              {/*        height="22"*/}
              {/*        viewBox="0 0 22 22"*/}
              {/*        fill="none"*/}
              {/*        xmlns="http://www.w3.org/2000/svg"*/}
              {/*      >*/}
              {/*        <path*/}
              {/*          d="M7.96875 18.594V12.0419C7.96875 11.546 7.96875 11.2981 8.06525 11.1087C8.15014 10.9421 8.28559 10.8066 8.4522 10.7217C8.6416 10.6252 8.88954 10.6252 9.38542 10.6252H11.8646C12.3605 10.6252 12.6084 10.6252 12.7978 10.7217C12.9644 10.8066 13.0999 10.9421 13.1847 11.1087C13.2813 11.2981 13.2813 11.546 13.2813 12.0419V18.594M9.75525 2.44751L3.75008 7.1182C3.34866 7.43041 3.14795 7.58652 3.00336 7.78202C2.87527 7.9552 2.77986 8.15029 2.7218 8.35771C2.65625 8.59188 2.65625 8.84615 2.65625 9.35469V15.7606C2.65625 16.7524 2.65625 17.2483 2.84926 17.6271C3.01904 17.9603 3.28994 18.2312 3.62314 18.4009C4.00194 18.594 4.49782 18.594 5.48958 18.594H15.7604C16.7522 18.594 17.2481 18.594 17.6269 18.4009C17.9601 18.2312 18.231 17.9603 18.4007 17.6271C18.5938 17.2483 18.5938 16.7524 18.5938 15.7606V9.35469C18.5938 8.84615 18.5937 8.59188 18.5282 8.35771C18.4701 8.15029 18.3747 7.9552 18.2466 7.78202C18.102 7.58652 17.9013 7.43041 17.4999 7.1182L11.4947 2.44751C11.1837 2.20557 11.0281 2.0846 10.8564 2.0381C10.7049 1.99707 10.5451 1.99707 10.3936 2.0381C10.2219 2.0846 10.0663 2.20557 9.75525 2.44751Z"*/}
              {/*          stroke="#A9A9A9"*/}
              {/*          strokeWidth="1.77083"*/}
              {/*          strokeLinecap="round"*/}
              {/*          strokeLinejoin="round"*/}
              {/*        />*/}
              {/*      </svg>*/}
              {/*    </div>*/}
              {/*    <span className="txt">Overview</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link*/}
              {/*    className={`${*/}
              {/*      history.location.pathname == "/messages" ? "active" : ""*/}
              {/*    }`}*/}
              {/*    to="/messages"*/}
              {/*  >*/}
              {/*    <div className="icon">*/}
              {/*      <svg*/}
              {/*        width="22"*/}
              {/*        height="22"*/}
              {/*        viewBox="0 0 22 22"*/}
              {/*        fill="none"*/}
              {/*        xmlns="http://www.w3.org/2000/svg"*/}
              {/*      >*/}
              {/*        <path*/}
              {/*          d="M19.0365 15.9373L13.1548 10.6248M8.09528 10.6248L2.21361 15.9373M1.77087 6.19775L9.00023 11.2583C9.58564 11.6681 9.87835 11.873 10.1967 11.9523C10.478 12.0225 10.7721 12.0225 11.0533 11.9523C11.3717 11.873 11.6644 11.6681 12.2499 11.2583L19.4792 6.19775M6.02087 17.7082H15.2292C16.7168 17.7082 17.4607 17.7082 18.0289 17.4187C18.5287 17.164 18.935 16.7576 19.1897 16.2578C19.4792 15.6896 19.4792 14.9458 19.4792 13.4582V7.7915C19.4792 6.30386 19.4792 5.56004 19.1897 4.99184C18.935 4.49204 18.5287 4.08568 18.0289 3.83102C17.4607 3.5415 16.7168 3.5415 15.2292 3.5415H6.02087C4.53323 3.5415 3.78941 3.5415 3.22121 3.83102C2.72141 4.08568 2.31505 4.49204 2.06039 4.99184C1.77087 5.56004 1.77087 6.30386 1.77087 7.7915V13.4582C1.77087 14.9458 1.77087 15.6896 2.06039 16.2578C2.31505 16.7576 2.72141 17.164 3.22121 17.4187C3.78941 17.7082 4.53323 17.7082 6.02087 17.7082Z"*/}
              {/*          stroke="#A9A9A9"*/}
              {/*          strokeWidth="1.77083"*/}
              {/*          strokeLinecap="round"*/}
              {/*          strokeLinejoin="round"*/}
              {/*        />*/}
              {/*      </svg>*/}
              {/*    </div>*/}
              {/*    <span className="txt">Messages</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link*/}
              {/*    className={`${*/}
              {/*      history.location.pathname == "/my-investments"*/}
              {/*        ? "active"*/}
              {/*        : ""*/}
              {/*    }`}*/}
              {/*    to="/my-investments"*/}
              {/*  >*/}
              {/*    <div className="icon">*/}
              {/*      <svg*/}
              {/*        width="21"*/}
              {/*        height="21"*/}
              {/*        viewBox="0 0 21 21"*/}
              {/*        fill="none"*/}
              {/*        xmlns="http://www.w3.org/2000/svg"*/}
              {/*      >*/}
              {/*        <path*/}
              {/*          d="M3.5 6.825C3.5 5.35486 3.5 4.61979 3.78611 4.05827C4.03778 3.56435 4.43935 3.16278 4.93327 2.91111C5.49479 2.625 6.22986 2.625 7.7 2.625H13.3C14.7701 2.625 15.5052 2.625 16.0667 2.91111C16.5607 3.16278 16.9622 3.56435 17.2139 4.05827C17.5 4.61979 17.5 5.35486 17.5 6.825V18.375L15.0938 16.625L12.9062 18.375L10.5 16.625L8.09375 18.375L5.90625 16.625L3.5 18.375V6.825Z"*/}
              {/*          stroke="#A9A9A9"*/}
              {/*          strokeWidth="1.75"*/}
              {/*          strokeLinecap="round"*/}
              {/*          strokeLinejoin="round"*/}
              {/*        />*/}
              {/*      </svg>*/}
              {/*    </div>*/}
              {/*    <span className="txt">My Investment</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link*/}
              {/*    className={`${*/}
              {/*      history.location.pathname == "/transactions" ? "active" : ""*/}
              {/*    }`}*/}
              {/*    to="/transactions"*/}
              {/*  >*/}
              {/*    <div className="icon">*/}
              {/*      <svg*/}
              {/*        width="22"*/}
              {/*        height="22"*/}
              {/*        viewBox="0 0 22 22"*/}
              {/*        fill="none"*/}
              {/*        xmlns="http://www.w3.org/2000/svg"*/}
              {/*      >*/}
              {/*        <path*/}
              {/*          d="M5.31254 5.31266L7.08337 3.54183M7.08337 3.54183L5.31254 1.771M7.08337 3.54183H5.31254C3.35653 3.54183 1.77087 5.12749 1.77087 7.0835M15.9375 15.9377L14.1667 17.7085M14.1667 17.7085L15.9375 19.4793M14.1667 17.7085H15.9375C17.8935 17.7085 19.4792 16.1228 19.4792 14.1668M9.02157 5.75537C9.61132 3.46405 11.6913 1.771 14.1667 1.771C17.1007 1.771 19.4792 4.14948 19.4792 7.0835C19.4792 9.55889 17.7862 11.6389 15.4949 12.2286M12.3959 14.1668C12.3959 17.1008 10.0174 19.4793 7.08337 19.4793C4.14936 19.4793 1.77087 17.1008 1.77087 14.1668C1.77087 11.2328 4.14936 8.85433 7.08337 8.85433C10.0174 8.85433 12.3959 11.2328 12.3959 14.1668Z"*/}
              {/*          stroke="#A9A9A9"*/}
              {/*          strokeWidth="1.77083"*/}
              {/*          strokeLinecap="round"*/}
              {/*          strokeLinejoin="round"*/}
              {/*        />*/}
              {/*      </svg>*/}
              {/*    </div>*/}
              {/*    <span className="txt">Transactions</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link
                  className={`${
                    history.location.pathname == "/account" ? "active" : ""
                  }`}
                  to="/account"
                >
                  <div className="icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.7083 18.5938C17.7083 17.3581 17.7083 16.7403 17.5558 16.2375C17.2124 15.1056 16.3266 14.2198 15.1947 13.8765C14.692 13.724 14.0742 13.724 12.8385 13.724H8.41142C7.17576 13.724 6.55793 13.724 6.0552 13.8765C4.92328 14.2198 4.03749 15.1056 3.69413 16.2375C3.54163 16.7403 3.54163 17.3581 3.54163 18.5938M14.6093 6.64063C14.6093 8.84113 12.8255 10.625 10.625 10.625C8.42445 10.625 6.64058 8.84113 6.64058 6.64063C6.64058 4.44012 8.42445 2.65625 10.625 2.65625C12.8255 2.65625 14.6093 4.44012 14.6093 6.64063Z"
                        stroke="#A9A9A9"
                        strokeWidth="1.77083"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="txt">Account</span>
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    history.location.pathname == "/get-support" ? "active" : ""
                  }`}
                  to="/get-support"
                >
                  <div className="icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.625 7.0835V10.6252M10.625 14.1668H10.6339M19.4792 10.6252C19.4792 15.5152 15.5151 19.4793 10.625 19.4793C5.73502 19.4793 1.77087 15.5152 1.77087 10.6252C1.77087 5.73514 5.73502 1.771 10.625 1.771C15.5151 1.771 19.4792 5.73514 19.4792 10.6252Z"
                        stroke="#A9A9A9"
                        strokeWidth="1.77083"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="txt">Get Support</span>
                </Link>
              </li>
            </ul>
          </div>

          <div
            className="sidebar-menu mt-auto pt-4 pb-4"
            style={{ marginLeft: "15px" }}
          >
            <ul>
              <li>
                <span style={{ display: "flex" }}>
                  <div className="icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1667 15.0521L18.5938 10.625M18.5938 10.625L14.1667 6.19792M18.5938 10.625H7.96875M7.96875 2.65625H6.90625C5.41861 2.65625 4.67479 2.65625 4.10659 2.94576C3.60678 3.20043 3.20043 3.60678 2.94576 4.10659C2.65625 4.67479 2.65625 5.41861 2.65625 6.90625V14.3438C2.65625 15.8314 2.65625 16.5752 2.94576 17.1434C3.20043 17.6432 3.60678 18.0496 4.10659 18.3042C4.67479 18.5938 5.41861 18.5938 6.90625 18.5938H7.96875"
                        stroke="#A9A9A9"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span
                    onClick={() => {
                      dispatch(logout());
                    }}
                    style={{ marginLeft: "5px" }}
                    className="txt"
                  >
                    Sign out
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- admin-sidebar-mobile-end --> */}
      {/* <!-- mobile-menubar --> */}
      <div className="mobile-menubar d-xl-none">
        <div className="mobile-menubar-inner">
          <div className="mobile-menubar-menu">
            <ul>
              <li>
                <Link
                  className={`${
                    history.location.pathname == "/dashboard" ? "active" : ""
                  }`}
                  to="/dashboard"
                >
                  <div className="icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.96875 18.594V12.0419C7.96875 11.546 7.96875 11.2981 8.06525 11.1087C8.15014 10.9421 8.28559 10.8066 8.4522 10.7217C8.6416 10.6252 8.88954 10.6252 9.38542 10.6252H11.8646C12.3605 10.6252 12.6084 10.6252 12.7978 10.7217C12.9644 10.8066 13.0999 10.9421 13.1847 11.1087C13.2813 11.2981 13.2813 11.546 13.2813 12.0419V18.594M9.75525 2.44751L3.75008 7.1182C3.34866 7.43041 3.14795 7.58652 3.00336 7.78202C2.87527 7.9552 2.77986 8.15029 2.7218 8.35771C2.65625 8.59188 2.65625 8.84615 2.65625 9.35469V15.7606C2.65625 16.7524 2.65625 17.2483 2.84926 17.6271C3.01904 17.9603 3.28994 18.2312 3.62314 18.4009C4.00194 18.594 4.49782 18.594 5.48958 18.594H15.7604C16.7522 18.594 17.2481 18.594 17.6269 18.4009C17.9601 18.2312 18.231 17.9603 18.4007 17.6271C18.5938 17.2483 18.5938 16.7524 18.5938 15.7606V9.35469C18.5938 8.84615 18.5937 8.59188 18.5282 8.35771C18.4701 8.15029 18.3747 7.9552 18.2466 7.78202C18.102 7.58652 17.9013 7.43041 17.4999 7.1182L11.4947 2.44751C11.1837 2.20557 11.0281 2.0846 10.8564 2.0381C10.7049 1.99707 10.5451 1.99707 10.3936 2.0381C10.2219 2.0846 10.0663 2.20557 9.75525 2.44751Z"
                        stroke="var(--theme__color3)"
                        strokeWidth="1.77083"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div className="menu-item-title">Home</div>
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    history.location.pathname == "/messages" ? "active" : ""
                  }`}
                  to="/messages"
                >
                  <div className="icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.0365 15.9373L13.1548 10.6248M8.09528 10.6248L2.21361 15.9373M1.77087 6.19775L9.00023 11.2583C9.58564 11.6681 9.87835 11.873 10.1967 11.9523C10.478 12.0225 10.7721 12.0225 11.0533 11.9523C11.3717 11.873 11.6644 11.6681 12.2499 11.2583L19.4792 6.19775M6.02087 17.7082H15.2292C16.7168 17.7082 17.4607 17.7082 18.0289 17.4187C18.5287 17.164 18.935 16.7576 19.1897 16.2578C19.4792 15.6896 19.4792 14.9458 19.4792 13.4582V7.7915C19.4792 6.30386 19.4792 5.56004 19.1897 4.99184C18.935 4.49204 18.5287 4.08568 18.0289 3.83102C17.4607 3.5415 16.7168 3.5415 15.2292 3.5415H6.02087C4.53323 3.5415 3.78941 3.5415 3.22121 3.83102C2.72141 4.08568 2.31505 4.49204 2.06039 4.99184C1.77087 5.56004 1.77087 6.30386 1.77087 7.7915V13.4582C1.77087 14.9458 1.77087 15.6896 2.06039 16.2578C2.31505 16.7576 2.72141 17.164 3.22121 17.4187C3.78941 17.7082 4.53323 17.7082 6.02087 17.7082Z"
                        stroke="var(--theme__color3)"
                        strokeWidth="1.77083"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div className="menu-item-title">Messages</div>
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    history.location.pathname == "/my-investments"
                      ? "active"
                      : ""
                  }`}
                  to="/my-investments"
                >
                  <div className="icon">
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.5 6.825C3.5 5.35486 3.5 4.61979 3.78611 4.05827C4.03778 3.56435 4.43935 3.16278 4.93327 2.91111C5.49479 2.625 6.22986 2.625 7.7 2.625H13.3C14.7701 2.625 15.5052 2.625 16.0667 2.91111C16.5607 3.16278 16.9622 3.56435 17.2139 4.05827C17.5 4.61979 17.5 5.35486 17.5 6.825V18.375L15.0938 16.625L12.9062 18.375L10.5 16.625L8.09375 18.375L5.90625 16.625L3.5 18.375V6.825Z"
                        stroke="var(--theme__color3)"
                        strokeWidth="1.75"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div className="menu-item-title">My Investments</div>
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    history.location.pathname == "/transactions" ? "active" : ""
                  }`}
                  to="/transactions"
                >
                  <div className="icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.31254 5.31266L7.08337 3.54183M7.08337 3.54183L5.31254 1.771M7.08337 3.54183H5.31254C3.35653 3.54183 1.77087 5.12749 1.77087 7.0835M15.9375 15.9377L14.1667 17.7085M14.1667 17.7085L15.9375 19.4793M14.1667 17.7085H15.9375C17.8935 17.7085 19.4792 16.1228 19.4792 14.1668M9.02157 5.75537C9.61132 3.46405 11.6913 1.771 14.1667 1.771C17.1007 1.771 19.4792 4.14948 19.4792 7.0835C19.4792 9.55889 17.7862 11.6389 15.4949 12.2286M12.3959 14.1668C12.3959 17.1008 10.0174 19.4793 7.08337 19.4793C4.14936 19.4793 1.77087 17.1008 1.77087 14.1668C1.77087 11.2328 4.14936 8.85433 7.08337 8.85433C10.0174 8.85433 12.3959 11.2328 12.3959 14.1668Z"
                        stroke="var(--theme__color3)"
                        strokeWidth="1.77083"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div className="menu-item-title">All transactions</div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- mobile-menubar-end --> */}
    </>
  );
};

export default NavBar;
